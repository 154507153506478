(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name people.filter:address
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('people')
    .filter('address', address);

  function address() {
    return function (person) {
      var bits = [];
      
      if (!angular.isDefined(person.address)) {
        return '';
      }

      if (person.address.company && person.address.company != '') {
        bits.push(person.address.company);
      }
      if (person.address.street && person.address.street != '') {
        bits.push(person.address.street);
      }
      if (person.address.suburb && person.address.suburb != '') {
        bits.push(person.address.suburb);
      }
      if (person.address.state && person.address.state != '') {
        bits.push(person.address.state);
      }
      if (angular.isDefined(person.address.postcode) && person.address.postcode !== '') {
        bits.push(person.address.postcode)
      }

      return bits.join(', ');
    };
  }
}());
